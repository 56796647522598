import $ from 'jquery';
import {CheckoutGateway, registerPaymentMethod} from '@paymentplugins/wc-stripe/checkout';

class CreditCardGateway extends CheckoutGateway {

    constructor(...props) {
        super(...props);
        this.message_container = this.params.notice_selector;
    }

    submit_error(error) {
        if (this.params.notice_location === 'bcf') {
            $('.wc-stripe-card-notice').remove();
            $('.wc-stripe_cc-new-method-container').append('<div class="wc-stripe-card-notice"></div>');
        }
        return super.submit_error(error, true);
    }

}

registerPaymentMethod(
    new CreditCardGateway(wc_stripe_credit_card_params, '#wc-stripe-card-element')
);